"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useDownloads;

var _react = require("react");

var _reactQuery = require("@tanstack/react-query");

var _moment = _interopRequireDefault(require("moment"));

var _lodash = _interopRequireDefault(require("lodash"));

var _apiService = require("~/app/3x/modules/services/api-service");

var _downloadsPageService = _interopRequireDefault(require("~/app/3x/modules/services/downloads-page-service"));

var _toastService = _interopRequireDefault(require("~/app/3x/modules/services/toast-service"));

var _transferManager = _interopRequireDefault(require("~/app/3x/modules/services/transfer-manager"));

var PAGE_SIZE = 100;

function useDownloads(userId) {
  var [downloads, setDownloads] = (0, _react.useState)([]);
  var transfers = _transferManager.default.transfers;
  var [active, setActive] = (0, _react.useState)(null);
  var [currentPage, setCurrentPage] = (0, _react.useState)(0);
  var [searchText, setSearchText] = (0, _react.useState)('');
  var [total, setTotal] = (0, _react.useState)(0);
  var [, setMostRecentLoadAt] = (0, _react.useState)(null);
  var downloadsQuery = (0, _reactQuery.useQuery)({
    queryKey: ['downloads', userId, searchText.trim(), currentPage],
    queryFn: () => _downloadsPageService.default.getAllDownloads({
      userId,
      start: currentPage * PAGE_SIZE,
      end: currentPage * PAGE_SIZE + (PAGE_SIZE - 1),
      query: searchText.trim()
    }),
    refetchOnWindowFocus: false
  });
  var isLoading = (downloadsQuery === null || downloadsQuery === void 0 ? void 0 : downloadsQuery.isLoading) || (downloadsQuery === null || downloadsQuery === void 0 ? void 0 : downloadsQuery.isFetching);
  var isError = downloadsQuery === null || downloadsQuery === void 0 ? void 0 : downloadsQuery.isError;
  var refetch = downloadsQuery === null || downloadsQuery === void 0 ? void 0 : downloadsQuery.refetch;

  var fetchNextPage = () => setCurrentPage(currentPage + 1);

  var search = text => {
    setDownloads([]);
    setCurrentPage(0);
    setSearchText(text);
  };

  var nbResults = total;
  var canShowMore = (downloads === null || downloads === void 0 ? void 0 : downloads.length) < nbResults; // Fetch downloads on initial load
  // Use transformDownloads to attach transfer object if active

  (0, _react.useEffect)(() => {
    if ((downloadsQuery === null || downloadsQuery === void 0 ? void 0 : downloadsQuery.isError) === true) {
      _toastService.default.create('error', 'Failed to load downloads');
    }

    if (downloadsQuery !== null && downloadsQuery !== void 0 && downloadsQuery.data) {
      var _downloadsQuery$data, _downloadsQuery$data$, _downloadsQuery$data2;

      var mostRecentLoadAt = new Date().getTime();

      var _total = (0, _apiService.getPaginationTotal)(downloadsQuery === null || downloadsQuery === void 0 ? void 0 : (_downloadsQuery$data = downloadsQuery.data) === null || _downloadsQuery$data === void 0 ? void 0 : (_downloadsQuery$data$ = _downloadsQuery$data.headers) === null || _downloadsQuery$data$ === void 0 ? void 0 : _downloadsQuery$data$['x-content-range']);

      var updatedDownloads = downloads.concat(transformDownloads((downloadsQuery === null || downloadsQuery === void 0 ? void 0 : (_downloadsQuery$data2 = downloadsQuery.data) === null || _downloadsQuery$data2 === void 0 ? void 0 : _downloadsQuery$data2.data) || [], transfers));
      updatedDownloads = _lodash.default.uniq(updatedDownloads, 'id');
      var activeDownloads = updatedDownloads.filter(download => !!download.transfer && download.transfer.status !== 'completed');
      setActive(activeDownloads);
      setDownloads([...updatedDownloads]);
      setMostRecentLoadAt(mostRecentLoadAt);
      setTotal(_total);
    }
  }, [downloadsQuery === null || downloadsQuery === void 0 ? void 0 : downloadsQuery.data, downloadsQuery === null || downloadsQuery === void 0 ? void 0 : downloadsQuery.isError]);
  return {
    isLoading,
    isError,
    refetch,
    data: downloads,
    canShowMore,
    search,
    currentPage,
    fetchNextPage,
    active,
    setActive
  };
}
/**
 * Transforms downloads and attaches transfer object if active
 * @param {Array} data      data returned by API
 * @param {Array} transfers Aspera transfers
 */


function transformDownloads(data, transfers) {
  var result = data.map(download => ({
    id: download._id,
    fileName: download.file.name,
    studio: download.asset.studio,
    project: download.project,
    asset: download.asset,
    created: _moment.default.unix(download.created).format('lll'),
    size: 0 // @todo Replace when API supports property

  }));
  transfers.forEach(transfer => {
    var index = result.findIndex(download => download.fileName === transfer.fileName);

    if (index !== -1) {
      result[index].transfer = transfer;
    }
  });
  return result;
}