"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.PAGE_SIZE = void 0;

var _apiService = _interopRequireWildcard(require("./api-service"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var PAGE_SIZE = 100;
exports.PAGE_SIZE = PAGE_SIZE;
var DownloadsPageService = {
  getAllDownloads(_ref) {
    var {
      userId,
      query,
      start = 0,
      end = 99
    } = _ref;
    var params = {
      user: userId,
      order: {
        downloaded: 'desc'
      }
    };

    if (query) {
      params.q = query;
    }

    return _apiService.default.client.get((0, _apiService.buildURL)('/downloads', params), {
      headers: {
        'X-Range': "resources=".concat(start, "-").concat(end)
      }
    });
  },

  fetchNewDownloads(_ref2) {
    var {
      userId,
      mostRecentLoadAt
    } = _ref2;
    var params = {
      user: userId,
      created: {
        from: mostRecentLoadAt
      },
      order: {
        downloaded: 'desc'
      }
    };
    return _apiService.default.client.get((0, _apiService.buildURL)('/downloads', params));
  }

};
var _default = DownloadsPageService;
exports.default = _default;