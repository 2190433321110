"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.STORE_NAME = exports.LOAD_SUCCESS = exports.LOAD_REQUEST = exports.LOAD_FAILURE = exports.LOAD = exports.INITIAL_STATE = void 0;
exports.reducer = reducer;
exports.selectors = void 0;
exports.watchFeedbackPage = watchFeedbackPage;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash.iserror"));

var _reduxActions = require("redux-actions");

var _effects = require("redux-saga/effects");

var _common = require("./common");

var _projects = require("./projects");

var _shareService = _interopRequireDefault(require("~/app/3x/modules/services/share-service"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var STORE_NAME = 'shareFeedbackPageStore';
exports.STORE_NAME = STORE_NAME;

function* loadPageData(_ref) {
  var {
    payload: shareId
  } = _ref;

  try {
    yield (0, _effects.put)(internalActions.loadPageDataRequest());
    var share = yield _shareService.default.getShareById(shareId);

    if ((0, _lodash.default)(share)) {
      throw new Error('Failed to load share');
    }

    var projects = yield (0, _effects.call)(_projects.fetchProjects, _projects.actions.getProjects({
      archived: false
    }));

    if ((0, _lodash.default)(projects)) {
      throw new Error('Failed to load projects');
    }

    yield (0, _effects.put)(internalActions.loadPageDataSuccess());
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.loadPageDataFailure(err));
    return err;
  }
}

var LOAD = 'share-feedback.load';
exports.LOAD = LOAD;
var LOAD_REQUEST = 'share-feedback.load.request';
exports.LOAD_REQUEST = LOAD_REQUEST;
var LOAD_SUCCESS = 'share-feedback.load.success';
exports.LOAD_SUCCESS = LOAD_SUCCESS;
var LOAD_FAILURE = 'share-feedback.load.failure';
exports.LOAD_FAILURE = LOAD_FAILURE;
var INITIAL_STATE = {
  isLoading: false,
  loadFailed: false
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case LOAD_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isLoading: true
        });
      }

    case LOAD_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isLoading: false,
          loadFailed: false
        });
      }

    case LOAD_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isLoading: false,
          loadFailed: true
        });
      }

    default:
      return state;
  }
}

var actions = {
  loadPageData: (0, _reduxActions.createAction)(LOAD)
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  loadPageDataRequest: (0, _reduxActions.createAction)(LOAD_REQUEST),
  loadPageDataSuccess: (0, _reduxActions.createAction)(LOAD_SUCCESS),
  loadPageDataFailure: (0, _reduxActions.createAction)(LOAD_FAILURE)
};
var selectors = {
  isLoading,
  isReady,
  didLoadFail
};
exports.selectors = selectors;

function isLoading(state) {
  var {
    shareFeedbackPageStore
  } = state;
  return shareFeedbackPageStore.isLoading;
}

function isReady(state) {
  return _projects.selectors.isLoaded(state);
}

function didLoadFail(state) {
  var {
    shareFeedbackPageStore
  } = state;
  return shareFeedbackPageStore.loadFailed;
}

function* watchFeedbackPage() {
  yield (0, _effects.takeLatest)(LOAD, loadPageData);
}