"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.STORE_NAME = exports.SAVE_FEEDBACK_DATA = exports.LOAD_SUCCESS = exports.LOAD_REQUEST = exports.LOAD_PREVIEWS_SUCCESS = exports.LOAD_PREVIEWS_REQUEST = exports.LOAD_PREVIEWS_FAILURE = exports.LOAD_PREVIEWS = exports.LOAD_FAILURE = exports.LOAD = exports.INITIAL_STATE = void 0;
exports.reducer = reducer;
exports.selectors = void 0;
exports.watchShareViewPage = watchShareViewPage;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

var _lodash2 = _interopRequireDefault(require("lodash.iserror"));

var _reduxActions = require("redux-actions");

var _effects = require("redux-saga/effects");

var _common = require("./common");

var _projects = require("./projects");

var _previews = require("./previews");

var _shareService = _interopRequireDefault(require("~/app/3x/modules/services/share-service"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var STORE_NAME = 'shareViewPageStore';
exports.STORE_NAME = STORE_NAME;

function* loadPageData(_ref) {
  var {
    payload: shareId
  } = _ref;

  try {
    yield (0, _effects.put)(internalActions.loadPageDataRequest());
    var projects = yield (0, _effects.call)(_projects.fetchProjects, _projects.actions.getProjects());

    if ((0, _lodash2.default)(projects)) {
      throw new Error('Failed to load projects');
    }

    var share = yield _shareService.default.getShareById(shareId);

    if ((0, _lodash2.default)(share)) {
      throw new Error('Failed to load share');
    }

    yield (0, _effects.put)(internalActions.loadPageDataSuccess());
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.loadPageDataFailure(err));
    return err;
  }
}

function* loadAssetPreviews(_ref2) {
  var {
    payload: {
      assetId,
      shareId
    }
  } = _ref2;

  try {
    yield (0, _effects.put)(internalActions.loadAssetPreviewsRequest());
    var previews = yield (0, _effects.call)(_previews.fetchPreviews, _previews.actions.getAssetPreviews({
      assetId,
      shareId
    }));

    if ((0, _lodash2.default)(previews)) {
      throw new Error('Failed to load previews');
    }

    yield (0, _effects.put)(internalActions.loadAssetPreviewsSuccess());
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.loadAssetPreviewsFailure(err));
    return err;
  }
}

var LOAD = 'share-view.load';
exports.LOAD = LOAD;
var LOAD_REQUEST = 'share-view.load.request';
exports.LOAD_REQUEST = LOAD_REQUEST;
var LOAD_SUCCESS = 'share-view.load.success';
exports.LOAD_SUCCESS = LOAD_SUCCESS;
var LOAD_FAILURE = 'share-view.load.failure';
exports.LOAD_FAILURE = LOAD_FAILURE;
var LOAD_PREVIEWS = 'previews.load';
exports.LOAD_PREVIEWS = LOAD_PREVIEWS;
var LOAD_PREVIEWS_REQUEST = 'previews.load.request';
exports.LOAD_PREVIEWS_REQUEST = LOAD_PREVIEWS_REQUEST;
var LOAD_PREVIEWS_SUCCESS = 'previews.load.success';
exports.LOAD_PREVIEWS_SUCCESS = LOAD_PREVIEWS_SUCCESS;
var LOAD_PREVIEWS_FAILURE = 'previews.load.failure';
exports.LOAD_PREVIEWS_FAILURE = LOAD_PREVIEWS_FAILURE;
var SAVE_FEEDBACK_DATA = 'share-view.feedback.save';
exports.SAVE_FEEDBACK_DATA = SAVE_FEEDBACK_DATA;
var INITIAL_STATE = {
  isLoading: false,
  loadFailed: false,
  arePreviewsLoading: false,
  didPreviewsLoadFail: false,
  feedback: {}
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case LOAD_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isLoading: true
        });
      }

    case LOAD_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isLoading: false,
          loadFailed: false
        });
      }

    case LOAD_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isLoading: false,
          loadFailed: true
        });
      }

    case LOAD_PREVIEWS_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          arePreviewsLoading: true
        });
      }

    case LOAD_PREVIEWS_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          arePreviewsLoading: false,
          didPreviewsLoadFail: false
        });
      }

    case LOAD_PREVIEWS_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          arePreviewsLoading: false,
          didPreviewsLoadFail: true
        });
      }

    case SAVE_FEEDBACK_DATA:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          feedback: _lodash.default.merge(state.feedback, action.payload)
        });
      }

    default:
      return state;
  }
}

var actions = {
  loadPageData: (0, _reduxActions.createAction)(LOAD),
  loadAssetPreviews: (0, _reduxActions.createAction)(LOAD_PREVIEWS),
  saveFeedbackData: (0, _reduxActions.createAction)(SAVE_FEEDBACK_DATA)
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  loadPageDataRequest: (0, _reduxActions.createAction)(LOAD_REQUEST),
  loadPageDataSuccess: (0, _reduxActions.createAction)(LOAD_SUCCESS),
  loadPageDataFailure: (0, _reduxActions.createAction)(LOAD_FAILURE),
  loadAssetPreviewsRequest: (0, _reduxActions.createAction)(LOAD_PREVIEWS_REQUEST),
  loadAssetPreviewsSuccess: (0, _reduxActions.createAction)(LOAD_PREVIEWS_SUCCESS),
  loadAssetPreviewsFailure: (0, _reduxActions.createAction)(LOAD_PREVIEWS_FAILURE)
};
var selectors = {
  isPageLoading,
  isPageReady,
  isPageLoadError,
  arePreviewsLoading,
  arePreviewsReady,
  didPreviewsLoadFail,
  getFeedbackData
};
exports.selectors = selectors;

function isPageLoading(state) {
  var {
    shareViewPageStore
  } = state;
  return shareViewPageStore.isLoading;
}

function isPageReady(state) {
  return _projects.selectors.isLoaded(state);
}

function isPageLoadError(state) {
  var {
    shareViewPageStore
  } = state;
  return shareViewPageStore.loadFailed;
}

function arePreviewsLoading(state) {
  var {
    shareViewPageStore
  } = state;
  return shareViewPageStore.arePreviewsLoading;
}

function arePreviewsReady(state, assetId) {
  if (!assetId) {
    return false;
  }

  return _previews.selectors.areAssetPreviewsLoaded(state, assetId);
}

function didPreviewsLoadFail(state) {
  var {
    shareViewPageStore
  } = state;
  return shareViewPageStore.didPreviewsLoadFail;
}

function getFeedbackData(state) {
  var shareViewPageStore = state.shareViewPageStore;
  return shareViewPageStore.feedback;
}

function* watchShareViewPage() {
  yield (0, _effects.takeLatest)(LOAD, loadPageData);
  yield (0, _effects.takeLatest)(LOAD_PREVIEWS, loadAssetPreviews);
}