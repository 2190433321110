"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.UPDATE_TRANSLATIONS = exports.STORE_NAME = exports.RESET_STORES = exports.LOAD_PAGE_SUCCESS = exports.LOAD_PAGE_REQUEST = exports.LOAD_PAGE_FAILURE = exports.LOAD_PAGE = exports.LOAD_MEMORIES_TRANSLATIONS_SUCCESS = exports.LOAD_MEMORIES_TRANSLATIONS_REQUEST = exports.LOAD_MEMORIES_TRANSLATIONS_FAILURE = exports.LOAD_MEMORIES_TRANSLATIONS = exports.LOAD_MACHINE_TRANSLATIONS_SUCCESS = exports.LOAD_MACHINE_TRANSLATIONS_REQUEST = exports.LOAD_MACHINE_TRANSLATIONS_FAILURE = exports.LOAD_MACHINE_TRANSLATIONS = exports.INITIAL_STATE = exports.IMPORT_TRANSLATIONS_SUCCESS = exports.IMPORT_TRANSLATIONS_REQUEST = exports.IMPORT_TRANSLATIONS_FAILURE = exports.IMPORT_TRANSLATIONS = void 0;
exports.reducer = reducer;
exports.selectors = void 0;
exports.watchSubtitlerPage = watchSubtitlerPage;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

var _lodash2 = _interopRequireDefault(require("lodash.iserror"));

var _effects = require("redux-saga/effects");

var _reduxActions = require("redux-actions");

var _common = require("./common");

var _previewService = _interopRequireDefault(require("../modules/services/preview-service"));

var _subtitleService = _interopRequireDefault(require("../modules/services/subtitle-service"));

var _workRequests = require("./work-requests");

var _translations = require("./translations");

var _languages = require("./languages");

var _previews = require("./previews");

var _translations2 = require("~/app/3x/store/translations");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var STORE_NAME = 'subtitlerStore';
exports.STORE_NAME = STORE_NAME;
var RESET_STORES = 'session.reset-stores';
exports.RESET_STORES = RESET_STORES;

function* loadPageData(_ref) {
  var {
    payload
  } = _ref;
  var {
    workRequestId,
    mode
  } = payload;
  var isPrintMode = mode === 'print';
  var isPrintDateMode = mode === 'printdate';
  var isPrint = isPrintMode || isPrintDateMode;
  var error = null;

  try {
    yield (0, _effects.put)(internalActions.loadPageRequest(workRequestId));
    var workRequest = yield (0, _effects.call)(_workRequests.fetchWorkRequestById, _workRequests.actions.getWorkRequestById(workRequestId));

    if ((0, _lodash2.default)(workRequest)) {
      throw workRequest;
    }

    if (!workRequest) {
      error = 'Oops... An Unexpected error has occurred';
      throw new Error(error);
    }

    if (workRequest.status === 404) {
      error = 'Oops... This Order Does Not Exist';
      throw new Error(error);
    }

    var isGfxMode = (0, _common.isGfxOrder)(workRequest);
    var isDedicatedGfxMode = (0, _common.isDedicatedGfxOrder)(workRequest);
    var isNarrationMode = (0, _common.isNarrationOrder)(workRequest);
    var isDialogueMode = (0, _common.isDialogueOrder)(workRequest) || isNarrationMode;
    var languagesTask;
    var languages = yield (0, _effects.select)(state => _languages.selectors.getLanguages(state));

    var language = _lodash.default.find(languages, {
      code: workRequest.language
    });

    if (!languages || !language) {
      languagesTask = yield (0, _effects.fork)(_languages.fetchLanguages, _languages.actions.getLanguages());
    }

    var {
      asset,
      auto: automatedLocalization
    } = workRequest;
    var ovTranslationsTask;
    var graphicsTranslationsTask;
    var dialogueTranslationsTask;
    var ovTranslations;
    var graphicsTranslations;
    var dialogueTranslations;
    var translations;
    var translationLines;

    if (!isPrint && !isGfxMode && !isDialogueMode && !isNarrationMode) {
      throw new Error('this is an OV order and should not be loaded in the subtitler');
    }

    if (languagesTask) {
      languages = yield (0, _effects.join)(languagesTask);
      language = _lodash.default.find(languages, {
        code: workRequest.language
      });

      if (!language) {
        throw new Error('Could not find language data for the work request');
      }
    } // Fetch most recent, english preview


    var previewTask = yield (0, _effects.fork)(fetchTranslationPreview, asset._id);
    var translationLanguagesTask = yield (0, _effects.fork)(_translations.fetchTranslationLanguages, _translations.actions.getTranslationLanguages());
    var translationLanguages = yield (0, _effects.join)(translationLanguagesTask);

    if ((0, _lodash2.default)(translationLanguages)) {
      throw translationLanguages;
    } // Join forked tasks


    var preview = yield (0, _effects.join)(previewTask);

    if ((0, _lodash2.default)(preview)) {
      throw preview;
    }

    if (!isPrintDateMode) {
      var _asset$transcription, _asset$transcription2, _asset$transcription3, _ovTranslations, _ovTranslations2, _graphicsTranslations, _dialogueTranslations;

      if (isDialogueMode && !(asset !== null && asset !== void 0 && (_asset$transcription = asset.transcription) !== null && _asset$transcription !== void 0 && _asset$transcription._id) || isGfxMode && !(asset !== null && asset !== void 0 && (_asset$transcription2 = asset.transcription) !== null && _asset$transcription2 !== void 0 && _asset$transcription2.graphics) || isPrint && !(asset !== null && asset !== void 0 && (_asset$transcription3 = asset.transcription) !== null && _asset$transcription3 !== void 0 && _asset$transcription3._id)) {
        throw new Error('Asset does not have an OV transcription');
      } // Join our other tasks that happened in parallel


      var transcripts = (0, _common.getTranscriptsForOrder)(workRequest);

      if (transcripts._id) {
        ovTranslationsTask = yield (0, _effects.fork)(_translations2.fetchTranslationById, _translations.actions.getTranslationById(asset.transcription._id));
      }

      if (transcripts.graphics) {
        graphicsTranslationsTask = yield (0, _effects.fork)(_translations2.fetchTranslationById, _translations.actions.getTranslationById(asset.transcription.graphics));
      }

      if (transcripts.autogfx) {
        graphicsTranslationsTask = yield (0, _effects.fork)(_translations2.fetchTranslationById, _translations.actions.getTranslationById(asset.transcription.autogfx));
      }

      if (transcripts.dialogue) {
        dialogueTranslationsTask = yield (0, _effects.fork)(_translations2.fetchTranslationById, _translations.actions.getTranslationById(asset.transcription.dialogue));
      }

      ovTranslations = ovTranslationsTask ? yield (0, _effects.join)(ovTranslationsTask) : null;

      if ((0, _lodash2.default)(ovTranslations) || !((_ovTranslations = ovTranslations) !== null && _ovTranslations !== void 0 && _ovTranslations.lines) && !((_ovTranslations2 = ovTranslations) !== null && _ovTranslations2 !== void 0 && _ovTranslations2.printLines) && isPrint) {
        throw ovTranslations;
      }

      graphicsTranslations = graphicsTranslationsTask ? yield (0, _effects.join)(graphicsTranslationsTask) : null;

      if ((0, _lodash2.default)(graphicsTranslations)) {
        throw graphicsTranslations;
      }

      dialogueTranslations = dialogueTranslationsTask ? yield (0, _effects.join)(dialogueTranslationsTask) : null;

      if ((0, _lodash2.default)(dialogueTranslations)) {
        throw dialogueTranslations;
      } // Add GFX tag to graphicsTranslations and dialogueTranslations lines to differentiate GRAPHICS from DIALOGUE when they will be processed together


      if ((_graphicsTranslations = graphicsTranslations) !== null && _graphicsTranslations !== void 0 && _graphicsTranslations.lines) {
        graphicsTranslations = _objectSpread(_objectSpread({}, graphicsTranslations), {}, {
          lines: _lodash.default.map(graphicsTranslations.lines, e => _objectSpread(_objectSpread({}, e), {}, {
            gfx: true
          })) || []
        });
      }

      if ((_dialogueTranslations = dialogueTranslations) !== null && _dialogueTranslations !== void 0 && _dialogueTranslations.lines) {
        dialogueTranslations = _objectSpread(_objectSpread({}, dialogueTranslations), {}, {
          lines: _lodash.default.map(dialogueTranslations.lines, e => _objectSpread(_objectSpread({}, e), {}, {
            gfx: false
          })) || []
        });
      }

      if (isGfxMode && !isDialogueMode && !ovTranslations) {
        ovTranslations = graphicsTranslations;
      }

      if (graphicsTranslations && !ovTranslations) {
        if (isDialogueMode && dialogueTranslations) {
          ovTranslations = _objectSpread(_objectSpread({}, dialogueTranslations), {}, {
            lines: _subtitleService.default.sortLinesByRange([...graphicsTranslations.lines.map(e => _objectSpread(_objectSpread({}, e), {}, {
              gfx: true,
              automatedLocalization
            })), ...dialogueTranslations.lines], automatedLocalization)
          });
        } else {
          ovTranslations = graphicsTranslations;
        }
      } // translationLines are the lines edited by the user


      if (transcripts._id) {
        if (isPrint) {
          var _workRequest$translat;

          translationLines = ((_workRequest$translat = workRequest.translation) === null || _workRequest$translat === void 0 ? void 0 : _workRequest$translat.printLines) || [];
        } else if (!(transcripts.autogfx || transcripts.graphics)) {
          var _workRequest$translat2;

          translationLines = ((_workRequest$translat2 = workRequest.translation) === null || _workRequest$translat2 === void 0 ? void 0 : _workRequest$translat2.lines) || [];
        } else {
          var _workRequest$translat3, _workRequest$graphics;

          translationLines = _subtitleService.default.sortLinesByTimeCodes([...((_workRequest$translat3 = workRequest.translation) === null || _workRequest$translat3 === void 0 ? void 0 : _workRequest$translat3.lines), ...((_workRequest$graphics = workRequest.graphicsTranslation) === null || _workRequest$graphics === void 0 ? void 0 : _workRequest$graphics.lines)]);
        }
      } else if (transcripts.autogfx || transcripts.graphics) {
        if (transcripts.dialogue) {
          var _workRequest$translat4, _workRequest$graphics2;

          translationLines = _subtitleService.default.sortLinesByTimeCodes([...((_workRequest$translat4 = workRequest.translation) === null || _workRequest$translat4 === void 0 ? void 0 : _workRequest$translat4.lines), ...((_workRequest$graphics2 = workRequest.graphicsTranslation) === null || _workRequest$graphics2 === void 0 ? void 0 : _workRequest$graphics2.lines)]);
        } else {
          var _workRequest$graphics3;

          translationLines = ((_workRequest$graphics3 = workRequest.graphicsTranslation) === null || _workRequest$graphics3 === void 0 ? void 0 : _workRequest$graphics3.lines) || [];
        }
      } else {
        var _workRequest$translat5;

        translationLines = ((_workRequest$translat5 = workRequest.translation) === null || _workRequest$translat5 === void 0 ? void 0 : _workRequest$translat5.lines) || [];
      } // check if the line is merged, then merge the ovTranslations to match the saved translations


      if (ovTranslations.lines.length > translationLines.length) {
        translationLines.forEach((translation, index) => {
          if (translation.merged) {
            // remove the merged cells from the array
            ovTranslations.lines.splice(index, translation.merged.length, _objectSpread(_objectSpread({}, ovTranslations.lines[index]), {}, {
              text: ovTranslations.lines.slice(index, index + translation.merged.length).map(e => {
                var _e$text;

                return (_e$text = e === null || e === void 0 ? void 0 : e.text) !== null && _e$text !== void 0 ? _e$text : '';
              }).join('\n'),
              number: index + 1
            }));
          } else if (ovTranslations.lines[index]) {
            ovTranslations.lines[index].number = index + 1;
          }
        });
      } // translations are the reference ov lines displayed for the user


      if (isPrintMode) {
        var _ovTranslations3;

        translations = _subtitleService.default.map3xPrintTranslations(translationLines, (_ovTranslations3 = ovTranslations) === null || _ovTranslations3 === void 0 ? void 0 : _ovTranslations3.printLines);
      } else if (transcripts.autogfx) {
        if (transcripts.dialogue) {
          var _ovTranslations4;

          translations = _subtitleService.default.map3xTranslations(translationLines, (_ovTranslations4 = ovTranslations) === null || _ovTranslations4 === void 0 ? void 0 : _ovTranslations4.lines).map(line => _objectSpread(_objectSpread({}, line), {}, {
            automatedLocalization
          }));
        } else {
          var _graphicsTranslations2;

          translations = _subtitleService.default.map3xTranslations(translationLines, (_graphicsTranslations2 = graphicsTranslations) === null || _graphicsTranslations2 === void 0 ? void 0 : _graphicsTranslations2.lines).map(line => _objectSpread(_objectSpread({}, line), {}, {
            gfx: true,
            automatedLocalization
          }));
        }
      } else if (transcripts.graphics) {
        if (transcripts.dialogue) {
          translations = _subtitleService.default.map3xTranslations(translationLines, ovTranslations.lines);
        } else {
          translations = _subtitleService.default.map3xTranslations(translationLines, graphicsTranslations.lines).map(line => _objectSpread(_objectSpread({}, line), {}, {
            gfx: true,
            showSubs: !isDedicatedGfxMode
          }));
        }
      } else if (transcripts._id) {
        var _ovTranslations5;

        translations = _subtitleService.default.map3xTranslations(translationLines, (_ovTranslations5 = ovTranslations) === null || _ovTranslations5 === void 0 ? void 0 : _ovTranslations5.lines);

        if (workRequest.auto) {
          translations.map(line => _objectSpread(_objectSpread({}, line), {}, {
            automatedLocalization
          }));
        }
      }
    } else {
      var _workRequest$translat6;

      translations = ((_workRequest$translat6 = workRequest.translation) === null || _workRequest$translat6 === void 0 ? void 0 : _workRequest$translat6.date) || '';
    }

    var translationFont = language.font(workRequest.studio);
    var vtt = !isPrint && translations ? _subtitleService.default.subsToEncodedVtt(translations) : '';
    var machineTranslations = [];
    var tmTranslations = [];

    if (Array.isArray(translations)) {
      if (translations.filter(t => t.translationFrom === 'mt').length) {
        machineTranslations = translations.map(e => e.translationFrom === 'mt' ? e.translationText : e.ovText);
      }

      if (translations.filter(t => t.translationFrom === 'tm').length) {
        tmTranslations = translations.map(e => e.translationFrom === 'tm' ? e.translationText : null);
      }
    }

    if (!isPrintDateMode && !translations) {
      throw new Error('This Work Request Does Not Contain Any Translations');
    }

    var subtitles = {
      workRequestId,
      asset,
      language,
      preview,
      translations,
      translationFont,
      tmTranslations,
      machineTranslations,
      translationLanguages,
      ovTranslations,
      vtt
    };
    yield (0, _effects.put)(internalActions.loadPageSuccess(subtitles));
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.loadPageFailure({
      id: workRequestId,
      error: error || err
    }));
    return err;
  }
}

function* loadMachineTranslations(_ref2) {
  var {
    payload
  } = _ref2;
  var workRequestId = payload.workRequestId;
  var mode = payload.mode;
  var language = payload.language.includes('-') ? payload.language.split('-') : payload.language;

  try {
    yield (0, _effects.put)(internalActions.loadMachineTranslationsRequest({
      workRequestId
    }));
    var isPrintDateMode = mode === 'printdate';
    var machineTranslationsTask;
    var workRequest = yield (0, _effects.select)(state => _workRequests.selectors.getWorkRequestById(state, workRequestId));
    var id = (0, _common.getTranscriptionId)(workRequest);

    if (Array.isArray(id)) {
      var [dialogue, graphics] = id;

      if (Array.isArray(language)) {
        // machine translations for dual languages - max 2 languages
        machineTranslationsTask = !isPrintDateMode ? yield (0, _effects.all)([(0, _effects.fork)(_translations.fetchMachineTranslations, _translations.actions.getMachineTranslations({
          id: dialogue,
          language: language[0]
        })), (0, _effects.fork)(_translations.fetchMachineTranslations, _translations.actions.getMachineTranslations({
          id: dialogue,
          language: language[1]
        })), (0, _effects.fork)(_translations.fetchMachineTranslations, _translations.actions.getMachineTranslations({
          id: graphics,
          language: language[0]
        })), (0, _effects.fork)(_translations.fetchMachineTranslations, _translations.actions.getMachineTranslations({
          id: graphics,
          language: language[1]
        }))]) : null;
      } else {
        machineTranslationsTask = !isPrintDateMode ? yield (0, _effects.all)([(0, _effects.fork)(_translations.fetchMachineTranslations, _translations.actions.getMachineTranslations({
          id: dialogue,
          language
        })), (0, _effects.fork)(_translations.fetchMachineTranslations, _translations.actions.getMachineTranslations({
          id: graphics,
          language
        }))]) : null;
      }
    } else if (Array.isArray(language)) {
      // machine translations for dual languages
      machineTranslationsTask = !isPrintDateMode ? yield (0, _effects.all)([(0, _effects.fork)(_translations.fetchMachineTranslations, _translations.actions.getMachineTranslations({
        id,
        language: language[0]
      })), (0, _effects.fork)(_translations.fetchMachineTranslations, _translations.actions.getMachineTranslations({
        id,
        language: language[1]
      }))]) : null;
    } else {
      machineTranslationsTask = !isPrintDateMode ? yield (0, _effects.fork)(_translations.fetchMachineTranslations, _translations.actions.getMachineTranslations({
        id,
        language
      })) : null;
    }

    var machineTranslations = !isPrintDateMode ? yield (0, _effects.join)(machineTranslationsTask) : [];

    if ((0, _lodash2.default)(machineTranslations)) {
      throw machineTranslations;
    }

    if (!machineTranslations || !machineTranslations.length) {
      throw new Error('machine translations failed to load');
    }

    if (Array.isArray(machineTranslations[0]) && Array.isArray(machineTranslations[1])) {
      // if dual languages for normal orders (no graphics and dialogue)
      if (Array.isArray(language) && machineTranslations.length === 2) {
        machineTranslations = machineTranslations[0].map((line, idx) => line + '<span></span>' + machineTranslations[1][idx]);
      } else {
        var dialogueTranslations = yield (0, _effects.select)(state => _translations.selectors.getTranslationById(state, workRequest.asset.transcription.dialogue));

        if (dialogueTranslations) {
          if (Array.isArray(language) && machineTranslations.length === 4) {
            // dual languages case
            // machineTranslation[0] && machineTranslation[1] : dialogue in both languages
            var dialogueMachineTranslationsLang1 = machineTranslations[0];
            var dialogueMachineTranslationsLang2 = machineTranslations[1];
            dialogueTranslations.lines.forEach((line, i) => {
              line.text = dialogueMachineTranslationsLang1[i] + '<span></span>' + dialogueMachineTranslationsLang2[i];
            });
          } else {
            var dialogueMachineTranslations = machineTranslations[0];
            dialogueTranslations.lines.forEach((line, i) => {
              line.text = dialogueMachineTranslations[i];
            });
          }
        }

        var gfxTranslations = yield (0, _effects.select)(state => _translations.selectors.getTranslationById(state, workRequest.auto ? workRequest.asset.transcription.autogfx : workRequest.asset.transcription.graphics));

        if (gfxTranslations) {
          if (Array.isArray(language) && machineTranslations.length === 4) {
            // dual languages case
            // machineTranslation[2] && machineTranslation[3] : graphics in both languages
            var gfxMachineTranslationsLang1 = machineTranslations[2];
            var gfxMachineTranslationsLang2 = machineTranslations[3];
            gfxTranslations.lines.forEach((line, i) => {
              line.text = gfxMachineTranslationsLang1[i] + '<span></span>' + gfxMachineTranslationsLang2[i];
            });
          } else {
            var gfxMachineTranslations = machineTranslations[1];
            gfxTranslations.lines.forEach((line, i) => {
              line.text = gfxMachineTranslations[i];
            });
          }
        }

        var translatedLines = _subtitleService.default.sortLinesByTimeCodes([...dialogueTranslations.lines, ...gfxTranslations.lines]);

        machineTranslations = translatedLines.map(line => line === null || line === void 0 ? void 0 : line.text);
      }
    }

    yield (0, _effects.put)(internalActions.loadMachineTranslationsSuccess({
      workRequestId,
      machineTranslations
    }));
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.loadMachineTranslationsFailure({
      id: workRequestId,
      error: err
    }));
    return err;
  }
}

function* loadTranslationMemories(_ref3) {
  var {
    payload
  } = _ref3;
  var workRequestId = payload.workRequestId;
  var language = payload.language;

  try {
    yield (0, _effects.put)(internalActions.loadTranslationMemoriesRequest({
      workRequestId
    }));
    var workRequest = yield (0, _effects.select)(state => _workRequests.selectors.getWorkRequestById(state, workRequestId));
    var translationMemoriesTask = null;
    var id = (0, _common.getTranslationId)(workRequest);

    if (Array.isArray(id)) {
      var [translation, graphicsTranslation] = id;
      translationMemoriesTask = yield (0, _effects.all)([(0, _effects.fork)(_translations.fetchTranslationMemories, _translations.actions.getTranslationMemories({
        id: translation,
        language
      })), (0, _effects.fork)(_translations.fetchTranslationMemories, _translations.actions.getTranslationMemories({
        id: graphicsTranslation,
        language
      }))]);
    } else {
      translationMemoriesTask = yield (0, _effects.fork)(_translations.fetchTranslationMemories, _translations.actions.getTranslationMemories({
        id,
        language
      }));
    }

    var translationMemories = translationMemoriesTask ? yield (0, _effects.join)(translationMemoriesTask) : [];

    if ((0, _lodash2.default)(translationMemories)) {
      throw translationMemories;
    }

    if (Array.isArray(translationMemories[0]) && Array.isArray(translationMemories[1])) {
      var _translation = workRequest.translation;

      if (_translation) {
        var memories = translationMemories[0];

        _translation.lines.forEach((line, i) => {
          line.text = memories[i] || line.text;
        });
      }

      var _graphicsTranslation = workRequest.graphicsTranslation;

      if (_graphicsTranslation) {
        var graphicsMemories = translationMemories[1];

        _graphicsTranslation.lines.forEach((line, i) => {
          line.text = graphicsMemories[i] || line.text;
        });
      }

      var translatedLines = _subtitleService.default.sortLinesByTimeCodes([..._translation.lines, ..._graphicsTranslation.lines]);

      translationMemories = translatedLines.map(line => line.text);
    }

    yield (0, _effects.put)(internalActions.loadTranslationMemoriesSuccess({
      workRequestId,
      tmTranslations: translationMemories
    }));
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.loadTranslationMemoriesFailure({
      id: workRequestId,
      error: err
    }));
    return err;
  }
}

function* fetchTranslationPreview(assetId) {
  try {
    var previews = yield (0, _effects.call)(_previews.fetchPreviews, _previews.actions.getAssetPreviews({
      assetId,
      type: 'none'
    }));

    if ((0, _lodash2.default)(previews)) {
      throw previews;
    }

    var preview = (0, _lodash.default)(previews).filter({
      language: 'ENG'
    }).sortBy(NoneDubSub, '-created').first();

    if (!preview) {
      return null;
    }

    var signedPreview = yield _previewService.default.getSignedPreviews(assetId, 'ENG', [preview._id]);
    return signedPreview.length ? signedPreview[0] : null;
  } catch (e) {
    return (0, _common.handleError)(e);
  }

  function NoneDubSub(preview) {
    return ['none', 'dub', 'sub'].indexOf(preview.type);
  }
}

function* importTranslations(_ref4) {
  var {
    payload
  } = _ref4;
  var {
    workRequestId,
    file
  } = payload;

  try {
    yield (0, _effects.put)(internalActions.importTranslationsRequest({
      workRequestId,
      file
    }));
    var workRequest = yield (0, _effects.select)(state => _workRequests.selectors.getWorkRequestById(state, workRequestId));
    var ovTranslations;
    var graphicsTranslations;

    if ((0, _common.isGfxOnlyOrder)(workRequest)) {
      graphicsTranslations = yield (0, _effects.select)(state => _translations.selectors.getTranslationById(state, workRequest.asset.transcription.graphics));
    } else if ((0, _common.isDedicatedGfxOrder)(workRequest) && ((0, _common.isDedicatedGfxOrder)(workRequest) || (0, _common.isNarrationOrder)(workRequest))) {
      graphicsTranslations = yield (0, _effects.select)(state => _translations.selectors.getTranslationById(state, workRequest.asset.transcription.graphics));
      ovTranslations = yield (0, _effects.select)(state => _translations.selectors.getTranslationById(state, workRequest.asset.transcription.dialogue));
    } else {
      ovTranslations = yield (0, _effects.select)(state => _translations.selectors.getTranslationById(state, workRequest.asset.transcription._id));
    }

    var lines = graphicsTranslations && ovTranslations ? _subtitleService.default.sortLinesByTimeCodes([...graphicsTranslations.lines, ...ovTranslations.lines]) : graphicsTranslations ? graphicsTranslations.lines : ovTranslations.lines;
    var translations = yield _subtitleService.default.importSubtitles(file, lines);
    var subtitles = {
      workRequestId,
      translations
    };
    yield (0, _effects.put)(internalActions.importTranslationsSuccess(subtitles));
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.importTranslationsFailure({
      id: workRequestId,
      error: err
    }));
    return err;
  }
}

var LOAD_PAGE = 'subtitler.load-page';
exports.LOAD_PAGE = LOAD_PAGE;
var LOAD_PAGE_REQUEST = 'subtitler.load-page.request';
exports.LOAD_PAGE_REQUEST = LOAD_PAGE_REQUEST;
var LOAD_PAGE_SUCCESS = 'subtitler.load-page.success';
exports.LOAD_PAGE_SUCCESS = LOAD_PAGE_SUCCESS;
var LOAD_PAGE_FAILURE = 'subtitler.load-page.failure';
exports.LOAD_PAGE_FAILURE = LOAD_PAGE_FAILURE;
var IMPORT_TRANSLATIONS = 'subtitler.translations.import';
exports.IMPORT_TRANSLATIONS = IMPORT_TRANSLATIONS;
var IMPORT_TRANSLATIONS_REQUEST = 'subtitler.translations.import.request';
exports.IMPORT_TRANSLATIONS_REQUEST = IMPORT_TRANSLATIONS_REQUEST;
var IMPORT_TRANSLATIONS_SUCCESS = 'subtitler.translations.import.success';
exports.IMPORT_TRANSLATIONS_SUCCESS = IMPORT_TRANSLATIONS_SUCCESS;
var IMPORT_TRANSLATIONS_FAILURE = 'subtitler.translations.import.failure';
exports.IMPORT_TRANSLATIONS_FAILURE = IMPORT_TRANSLATIONS_FAILURE;
var UPDATE_TRANSLATIONS = 'subtitler.translations.update';
exports.UPDATE_TRANSLATIONS = UPDATE_TRANSLATIONS;
var LOAD_MACHINE_TRANSLATIONS = 'subtitler.machine-translations.load';
exports.LOAD_MACHINE_TRANSLATIONS = LOAD_MACHINE_TRANSLATIONS;
var LOAD_MACHINE_TRANSLATIONS_REQUEST = 'subtitler.machine-translations.load.request';
exports.LOAD_MACHINE_TRANSLATIONS_REQUEST = LOAD_MACHINE_TRANSLATIONS_REQUEST;
var LOAD_MACHINE_TRANSLATIONS_SUCCESS = 'subtitler.machine-translations.load.success';
exports.LOAD_MACHINE_TRANSLATIONS_SUCCESS = LOAD_MACHINE_TRANSLATIONS_SUCCESS;
var LOAD_MACHINE_TRANSLATIONS_FAILURE = 'subtitler.machine-translations.load.failure';
exports.LOAD_MACHINE_TRANSLATIONS_FAILURE = LOAD_MACHINE_TRANSLATIONS_FAILURE;
var LOAD_MEMORIES_TRANSLATIONS = 'subtitler.translation-memories.load';
exports.LOAD_MEMORIES_TRANSLATIONS = LOAD_MEMORIES_TRANSLATIONS;
var LOAD_MEMORIES_TRANSLATIONS_REQUEST = 'subtitler.translation-memories.load.request';
exports.LOAD_MEMORIES_TRANSLATIONS_REQUEST = LOAD_MEMORIES_TRANSLATIONS_REQUEST;
var LOAD_MEMORIES_TRANSLATIONS_SUCCESS = 'subtitler.translation-memories.load.success';
exports.LOAD_MEMORIES_TRANSLATIONS_SUCCESS = LOAD_MEMORIES_TRANSLATIONS_SUCCESS;
var LOAD_MEMORIES_TRANSLATIONS_FAILURE = 'subtitler.translation-memories.load.failure';
exports.LOAD_MEMORIES_TRANSLATIONS_FAILURE = LOAD_MEMORIES_TRANSLATIONS_FAILURE;
var INITIAL_STATE = {
  isLoading: false,
  loadError: null,
  isImporting: false,
  subtitles: {},
  isMachineTranslationsLoading: false
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case LOAD_PAGE_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isLoading: true,
          loadError: null,
          // Reset stored subtitles as we only ever need one
          subtitles: {}
        });
      }

    case LOAD_PAGE_SUCCESS:
      {
        var subtitles = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          isLoading: false,
          loadError: null,
          subtitles: {
            [subtitles.workRequestId]: subtitles
          }
        });
      }

    case LOAD_PAGE_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isLoading: false,
          loadError: action.payload
        });
      }

    case IMPORT_TRANSLATIONS_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isImporting: true
        });
      }

    case IMPORT_TRANSLATIONS_SUCCESS:
      {
        var _subtitles = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          subtitles: {
            [_subtitles.workRequestId]: _objectSpread(_objectSpread({}, state.subtitles[_subtitles.workRequestId]), _subtitles)
          },
          isImporting: false
        });
      }

    case IMPORT_TRANSLATIONS_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isImporting: false
        });
      }

    case UPDATE_TRANSLATIONS:
      {
        var {
          workRequestId,
          translations
        } = action.payload;
        var _subtitles2 = {
          [workRequestId]: _objectSpread(_objectSpread({}, state.subtitles[workRequestId]), {}, {
            translations
          })
        };
        return _objectSpread(_objectSpread({}, state), {}, {
          subtitles: _subtitles2
        });
      }

    case LOAD_MACHINE_TRANSLATIONS_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isMachineTranslationsLoading: true
        });
      }

    case LOAD_MACHINE_TRANSLATIONS_SUCCESS:
      {
        var {
          workRequestId: _workRequestId,
          machineTranslations
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          subtitles: {
            [_workRequestId]: _objectSpread(_objectSpread({}, state.subtitles[_workRequestId]), {}, {
              machineTranslations
            })
          },
          isMachineTranslationsLoading: false
        });
      }

    case LOAD_MACHINE_TRANSLATIONS_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isMachineTranslationsLoading: false
        });
      }

    case LOAD_MEMORIES_TRANSLATIONS_REQUEST:
      {
        var {
          workRequestId: _workRequestId2
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          subtitles: {
            [_workRequestId2]: _objectSpread(_objectSpread({}, state.subtitles[_workRequestId2]), {}, {
              tmTranslations: [],
              isTranslationMemoriesLoading: true,
              isTranslationMemoriesFetched: false
            })
          }
        });
      }

    case LOAD_MEMORIES_TRANSLATIONS_SUCCESS:
      {
        var {
          workRequestId: _workRequestId3,
          tmTranslations
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          subtitles: {
            [_workRequestId3]: _objectSpread(_objectSpread({}, state.subtitles[_workRequestId3]), {}, {
              tmTranslations: tmTranslations,
              isTranslationMemoriesLoading: false,
              isTranslationMemoriesFetched: true
            })
          }
        });
      }

    case LOAD_MEMORIES_TRANSLATIONS_FAILURE:
      {
        var {
          workRequestId: _workRequestId4
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          subtitles: {
            [_workRequestId4]: _objectSpread(_objectSpread({}, state.subtitles[_workRequestId4]), {}, {
              isTranslationMemoriesLoading: false,
              isTranslationMemoriesFetched: false
            })
          }
        });
      }

    case RESET_STORES:
      return INITIAL_STATE;

    default:
      return state;
  }
}

var actions = {
  loadPageData: (0, _reduxActions.createAction)(LOAD_PAGE),
  importTranslations: (0, _reduxActions.createAction)(IMPORT_TRANSLATIONS),
  updateTranslations: (0, _reduxActions.createAction)(UPDATE_TRANSLATIONS),
  loadMachineTranslations: (0, _reduxActions.createAction)(LOAD_MACHINE_TRANSLATIONS),
  loadTranslationMemories: (0, _reduxActions.createAction)(LOAD_MEMORIES_TRANSLATIONS)
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  loadPageRequest: (0, _reduxActions.createAction)(LOAD_PAGE_REQUEST),
  loadPageSuccess: (0, _reduxActions.createAction)(LOAD_PAGE_SUCCESS),
  loadPageFailure: (0, _reduxActions.createAction)(LOAD_PAGE_FAILURE),
  importTranslationsRequest: (0, _reduxActions.createAction)(IMPORT_TRANSLATIONS_REQUEST),
  importTranslationsSuccess: (0, _reduxActions.createAction)(IMPORT_TRANSLATIONS_SUCCESS),
  importTranslationsFailure: (0, _reduxActions.createAction)(IMPORT_TRANSLATIONS_FAILURE),
  loadMachineTranslationsRequest: (0, _reduxActions.createAction)(LOAD_MACHINE_TRANSLATIONS_REQUEST),
  loadMachineTranslationsSuccess: (0, _reduxActions.createAction)(LOAD_MACHINE_TRANSLATIONS_SUCCESS),
  loadMachineTranslationsFailure: (0, _reduxActions.createAction)(LOAD_MACHINE_TRANSLATIONS_FAILURE),
  loadTranslationMemoriesRequest: (0, _reduxActions.createAction)(LOAD_MEMORIES_TRANSLATIONS_REQUEST),
  loadTranslationMemoriesSuccess: (0, _reduxActions.createAction)(LOAD_MEMORIES_TRANSLATIONS_SUCCESS),
  loadTranslationMemoriesFailure: (0, _reduxActions.createAction)(LOAD_MEMORIES_TRANSLATIONS_FAILURE)
};
var selectors = {
  isReady: (0, _common.createSelector)(STORE_NAME, isReady),
  didLoadFail: (0, _common.createSelector)(STORE_NAME, didLoadFail),
  loadError: (0, _common.createSelector)(STORE_NAME, loadError),
  isImportingTranslations: (0, _common.createSelector)(STORE_NAME, isImportingTranslations),
  getSubtitles: (0, _common.createSelector)(STORE_NAME, getSubtitles),
  isMachineTranslationsLoading: (0, _common.createSelector)(STORE_NAME, isMachineTranslationsLoading),
  isTranslationMemoriesLoading: (0, _common.createSelector)(STORE_NAME, isTranslationMemoriesLoading),
  isTranslationMemoriesFetched: (0, _common.createSelector)(STORE_NAME, isTranslationMemoriesFetched)
};
exports.selectors = selectors;

function isReady(state, workRequestId) {
  return !state.isLoading && !!getSubtitles(state, workRequestId);
}

function didLoadFail(state) {
  return !!state.loadError;
}

function loadError(state) {
  return state.loadError;
}

function isImportingTranslations(state) {
  return state.isImporting;
}

function getSubtitles(state, workRequestId) {
  return state.subtitles[workRequestId];
}

function isMachineTranslationsLoading(state) {
  return state.isMachineTranslationsLoading;
}

function isTranslationMemoriesLoading(state, workRequestId) {
  var _state$subtitles$work;

  return ((_state$subtitles$work = state.subtitles[workRequestId]) === null || _state$subtitles$work === void 0 ? void 0 : _state$subtitles$work.isTranslationMemoriesLoading) || false;
}

function isTranslationMemoriesFetched(state, workRequestId) {
  var container = state.subtitles[workRequestId];

  if (!container) {
    return false;
  }

  return container.isTranslationMemoriesFetched || !!container.tmTranslations.length;
}

function* watchSubtitlerPage() {
  yield (0, _effects.takeLatest)(LOAD_PAGE, loadPageData);
  yield (0, _effects.takeLatest)(IMPORT_TRANSLATIONS, importTranslations);
  yield (0, _effects.takeLatest)(LOAD_MACHINE_TRANSLATIONS, loadMachineTranslations);
  yield (0, _effects.takeLatest)(LOAD_MEMORIES_TRANSLATIONS, loadTranslationMemories);
}