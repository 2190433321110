"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _apiService = _interopRequireWildcard(require("./api-service"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var PREVIEWS_QUERY = "\nquery getPreviews($offset: Int, $limit: Int, $project: ID, $studio: ID, $asset: [ID], $page: Int) {\n  previews(offset: $offset, limit: $limit, project: $project, studio: $studio, asset: $asset, page: $page) {\n    nodes {\n      id\n      type\n      label\n      asset {\n        name\n        type\n        embargo\n      }\n      created\n      language\n      path\n      revision\n      embargo\n      project {\n        id\n        name\n      }\n      studio {\n        id\n        name\n      }\n    }\n    totalCount\n  }\n}";
var PreviewService = {
  getAllPreviews(params) {
    return _apiService.default.client.get((0, _apiService.buildURL)('/previews', params)).then(res => res === null || res === void 0 ? void 0 : res.data);
  },

  getPreviews(params) {
    var {
      page = 1,
      limit = 9
    } = params;
    return _apiService.default.graphql.request(PREVIEWS_QUERY, _objectSpread({
      offset: (page - 1) * limit,
      limit
    }, params));
  },

  getSignedPreviews(assetId, languageId, revisionIds) {
    return _apiService.default.client.get((0, _apiService.buildURL)('/previews', {
      asset: assetId,
      language: languageId,
      ids: revisionIds
    }), {
      headers: {
        'X-Range': "resources=0-29"
      }
    }).then(res => {
      return res === null || res === void 0 ? void 0 : res.data;
    });
  },

  deletePreview(id) {
    return _apiService.default.client.delete("/previews/".concat(id));
  },

  updatePreview(id, data) {
    return _apiService.default.client.put("/previews/".concat(id), data);
  },

  /**
   *
   * @param {
   * 	sub: boolean,
   * 	dub: boolean
   * } subDubTags
   * @returns
   * 	- 'sub' if only sub is available
   * 	- 'dub' if only dub is available
   * 	- 'both' if both sub and dub are available
   */
  findPreviewTypeFromTags(subDubTags) {
    if (subDubTags !== null && subDubTags !== void 0 && subDubTags.sub && subDubTags !== null && subDubTags !== void 0 && subDubTags.dub) {
      return 'both';
    } else if (subDubTags !== null && subDubTags !== void 0 && subDubTags.sub) {
      return 'sub';
    } else if (subDubTags !== null && subDubTags !== void 0 && subDubTags.dub) {
      return 'dub';
    }

    return 'none';
  }

};
var _default = PreviewService;
exports.default = _default;