"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.STORE_NAME = exports.RESET_STORES = exports.INITIAL_STATE = exports.FETCH_PREVIEWS_SUCCESS = exports.FETCH_PREVIEWS_REQUEST = exports.FETCH_PREVIEWS_FAILURE = exports.FETCH_PREVIEWS = void 0;
exports.fetchPreviews = fetchPreviews;
exports.reducer = reducer;
exports.selectors = void 0;
exports.watchPreviews = watchPreviews;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

var _lodash2 = _interopRequireDefault(require("lodash.get"));

var _reduxActions = require("redux-actions");

var _effects = require("redux-saga/effects");

var _common = require("./common");

var _previewService = _interopRequireDefault(require("~/app/3x/modules/services/preview-service"));

var _shareService = _interopRequireDefault(require("~/app/3x/modules/services/share-service"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var RESET_STORES = 'session.reset-stores';
exports.RESET_STORES = RESET_STORES;
var STORE_NAME = 'previewsStore';
exports.STORE_NAME = STORE_NAME;

function* fetchPreviews(_ref) {
  var {
    payload: {
      assetId,
      shareId,
      type
    }
  } = _ref;
  var params = {
    asset: assetId
  };

  if (type) {
    params.type = type;
  }

  try {
    yield (0, _effects.put)(internalActions.getAssetPreviewsRequest(assetId));
    var previews = yield _previewService.default.getAllPreviews(params); // If shareId is provided, then we need to filter out any previews
    // that don't belong to that share depending on share's languages

    if (shareId) {
      var share = yield _shareService.default.getShareById(shareId);
      var shareLanguages = (0, _lodash2.default)(share, 'languages', []);
      previews = _lodash.default.filter(previews, preview => {
        return _lodash.default.contains(shareLanguages, preview.language);
      });
    }

    yield (0, _effects.put)(internalActions.getAssetPreviewsSuccess({
      assetId,
      previews
    }));
    return previews;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.getAssetPreviewsFailure({
      id: assetId,
      error: err
    }));
    return err;
  }
}

var FETCH_PREVIEWS = 'previews.fetch';
exports.FETCH_PREVIEWS = FETCH_PREVIEWS;
var FETCH_PREVIEWS_REQUEST = 'previews.fetch.request';
exports.FETCH_PREVIEWS_REQUEST = FETCH_PREVIEWS_REQUEST;
var FETCH_PREVIEWS_SUCCESS = 'previews.fetch.success';
exports.FETCH_PREVIEWS_SUCCESS = FETCH_PREVIEWS_SUCCESS;
var FETCH_PREVIEWS_FAILURE = 'previews.fetch.failure';
exports.FETCH_PREVIEWS_FAILURE = FETCH_PREVIEWS_FAILURE;
var INITIAL_STATE = {
  previews: {},
  previewsByAsset: {}
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case RESET_STORES:
      return INITIAL_STATE;

    case FETCH_PREVIEWS_REQUEST:
      {
        var assetId = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          previewsByAsset: _objectSpread(_objectSpread({}, state.previewsByAsset), {}, {
            [assetId]: _objectSpread(_objectSpread({
              // Place lastLoadedAt: null first so that its overwritten if it already exists
              lastLoadedAt: null
            }, state.previewsByAsset[assetId]), {}, {
              isLoading: true
            })
          })
        });
      }

    case FETCH_PREVIEWS_SUCCESS:
      {
        var {
          assetId: _assetId,
          previews
        } = action.payload;
        var now = new Date();

        var previewIds = _lodash.default.map(previews, '_id');

        var transformedPreviews = _lodash.default.reduce(previews, (result, preview) => {
          result[preview._id] = {
            isLoading: false,
            lastLoadedAt: now,
            data: preview
          };
          return result;
        }, {});

        return _objectSpread(_objectSpread({}, state), {}, {
          previews: _objectSpread(_objectSpread({}, state.previews), transformedPreviews),
          previewsByAsset: _objectSpread(_objectSpread({}, state.previewsByAsset), {}, {
            [_assetId]: {
              isLoading: false,
              lastLoadedAt: now,
              previews: previewIds
            }
          })
        });
      }

    case FETCH_PREVIEWS_FAILURE:
      {
        var {
          assetId: _assetId2
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          previewsByAsset: {
            [_assetId2]: _objectSpread(_objectSpread({}, state.previewsByAsset[_assetId2]), {}, {
              isLoading: false
            })
          }
        });
      }

    default:
      return state;
  }
}

var actions = {
  getAssetPreviews: (0, _reduxActions.createAction)(FETCH_PREVIEWS)
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  getAssetPreviewsRequest: (0, _reduxActions.createAction)(FETCH_PREVIEWS_REQUEST),
  getAssetPreviewsSuccess: (0, _reduxActions.createAction)(FETCH_PREVIEWS_SUCCESS),
  getAssetPreviewsFailure: (0, _reduxActions.createAction)(FETCH_PREVIEWS_FAILURE)
};
var selectors = {
  areAssetPreviewsLoading: createSelector(areAssetPreviewsLoading),
  areAssetPreviewsLoaded: createSelector(areAssetPreviewsLoaded),
  getAssetPreviewsLastLoadedAt: createSelector(getAssetPreviewsLastLoadedAt),
  getAssetPreviews: createSelector(getAssetPreviews)
};
exports.selectors = selectors;

function areAssetPreviewsLoading(state, assetId) {
  var assetContainer = state.previewsByAsset[assetId];

  if (!assetContainer) {
    return false;
  }

  return assetContainer.isLoading;
}

function areAssetPreviewsLoaded(state, assetId) {
  var assetContainer = state.previewsByAsset[assetId];

  if (!assetContainer) {
    return false;
  }

  return !!assetContainer.lastLoadedAt;
}

function getAssetPreviewsLastLoadedAt(state, assetId) {
  var assetContainer = state.previewsByAsset[assetId];

  if (!assetContainer) {
    return null;
  }

  return assetContainer.lastLoadedAt;
}

function getAssetPreviews(state, assetId) {
  var assetContainer = state.previewsByAsset[assetId];

  if (!assetContainer) {
    return [];
  }

  return _lodash.default.map(assetContainer.previews, previewId => state.previews[previewId].data);
}
/**
 * Helper method that grabs our specific part of the global store, then passes it into the given selector.
 * This makes it so that each selector doesn't have to grab the sub-state itself, reducing code duplication.
 */


function createSelector(selector) {
  return function selectorWrapper(state) {
    var previewsStore = state[STORE_NAME];

    for (var _len = arguments.length, rest = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      rest[_key - 1] = arguments[_key];
    }

    return selector(previewsStore, ...rest);
  };
}

function* watchPreviews() {
  yield (0, _effects.takeLatest)(FETCH_PREVIEWS, fetchPreviews);
}